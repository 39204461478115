<template>
  <div class="scroll-table h-full flex flex-col">
    <table class="w-full" :style="{ tableLayout: tableLayout }">
      <colgroup>
        <col
          :style="{ width: item.width }"
          v-for="(item, index) in columns"
          :key="index"
        />
      </colgroup>
      <thead>
        <tr>
          <th class="truncate" v-for="(item, index) in columns" :key="index">
            {{ item.title || '-' }}
          </th>
        </tr>
      </thead>
    </table>
    <div class="flex-1 overflow-hidden">
      <vueSeamlessScroll
        :class-option="classOption"
        :data="datasource"
        v-if="infiniteScroll"
        @click.native="handleBodyClick"
        style="cursor: pointer;"
      >
        <table class="w-full" :style="{ tableLayout: tableLayout }">
          <colgroup>
            <col
              :style="{ width: item.width }"
              v-for="(item, index) in columns"
              :key="index"
            />
          </colgroup>
          <tbody>
            <tr v-for="(item, index) in datasource" :key="index">
              <td
                :data-index="index"
                class="truncate"
                v-for="(td, i) in columns"
                :key="i"
                :title="
                  td.format
                    ? td.format(item[td.key])
                    : item[td.key] === -1
                    ? '-'
                    : item[td.key] ?? '-'
                "
              >
                <CustomRender
                  v-if="td.render"
                  :render="h => td.render(h, item)"
                ></CustomRender>
                <template v-else>
                  {{
                    td.format
                      ? td.format(item[td.key])
                      : item[td.key] === -1
                      ? '-'
                      : item[td.key] ?? '-'
                  }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
        <slot name="loading-text" v-if="!finished">
          <div class="flex justify-center items-center" style="gap: 8px;">
            <a-icon type="sync" spin />{{ loadingText }}
          </div>
        </slot>
      </vueSeamlessScroll>

      <x-scroll-view
        :disabled="false"
        v-on:scrolltolower="loadMore"
        :immediateCheck="true"
        :lower-threshold="0"
        v-else
      >
        <table class="w-full" :style="{ tableLayout: tableLayout }">
          <colgroup>
            <col
              :style="{ width: item.width }"
              v-for="(item, index) in columns"
              :key="index"
            />
          </colgroup>
          <tbody>
            <tr
              @click="handleCellClick(item, index)"
              v-for="(item, index) in datasource"
              :key="index"
            >
              <td
                class="truncate"
                v-for="(td, i) in columns"
                :key="i"
                :title="
                  td.format
                    ? td.format(item[td.key])
                    : item[td.key] === -1
                    ? '-'
                    : item[td.key] ?? '-'
                "
              >
                <CustomRender
                  v-if="td.render"
                  :render="h => td.render(h, item)"
                ></CustomRender>
                <template v-else>
                  {{
                    td.format
                      ? td.format(item[td.key])
                      : item[td.key] === -1
                      ? '-'
                      : item[td.key] ?? '-'
                  }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
        <slot name="loading-text" v-if="!finished">
          <div class="flex justify-center items-center" style="gap: 8px;">
            <a-icon type="sync" spin />{{ loadingText }}
          </div>
        </slot>
      </x-scroll-view>
    </div>
  </div>
</template>

<script>
import { nowSize } from '@/utils/common';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import vueSeamlessScroll from 'vue-seamless-scroll';
import CustomRender from '@/components/pagination-table/components/render';

@Component({ components: { vueSeamlessScroll, CustomRender } })
export default class ScrollTable extends Vue {
  @Prop({ type: Array, default: () => [] }) columns;
  @Prop({ type: [Array, Function], default: () => [] }) data;
  @Prop({ type: Object, default: () => {} }) paginationConfig;
  @Prop({ type: String, default: '加载中...' }) loadingText;
  @Prop({ type: String, default: '底线' }) finishedText;
  @Prop({ type: String, default: 'fixed' }) tableLayout;
  @Prop({ type: Boolean, default: true }) infiniteScroll; // 是否无缝滚动

  classOption = {
    step: 0.5,
    singleHeight: nowSize(28),
    waitTime: 2 * 1000,
  };

  @Watch('data', { immediate: true })
  async dataChange(val) {
    if (typeof val == 'object') {
      this.datasource = val;
      this.finished = true;
    }
    if (typeof val === 'function' && this.infiniteScroll) {
      this.finished = true;
      this.datasource = await val(this.pagination);
    }
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };
  datasource = [];
  finished = false;
  //是否是初始化
  isInit = true;
  loadMore() {
    if (this.finished) return;
    if (!this.isInit) {
      this.pagination.current += 1;
    }
    this.fetchData();
    this.$emit('load');
    this.isInit = false;
  }

  handleCellClick(record, index) {
    this.$emit('cellClick', { record, index });
  }

  handleBodyClick(e) {
    const index = e.target.dataset.index;
    const record = this.datasource[index];
    this.$emit('cellClick', { record, index });
  }

  async fetchData() {
    const { records, pages } = await this.data(this.pagination);
    this.datasource.push(...records);
    if (this.pagination.current >= pages) return (this.finished = true);
  }

  refresh() {
    this.datasource = [];
    this.pagination.current = 1;
    this.finished = false;
    this.fetchData();
  }

  created() {
    Object.assign(this.pagination, this.paginationConfig);
  }
}
</script>

<style lang="less" scoped>
@import '@/assets/css/mixin.less';

.scroll-table {
  .px2rem(padding, 16);
  .px2rem(font-size, 12);
  overflow: hidden;
  table {
    th,
    td {
      .px2rem(padding-top, 5);
      .px2rem(padding-bottom, 5);
      .px2rem(padding-left, 15);
      .px2rem(padding-right, 15);
      color: var(--screen-font-2);
      font-size: 0.12rem;
    }
    th {
      .px2rem(padding-top, 0);
      .px2rem(padding-bottom, 10);
      color: #9ac0d9;
    }
    tbody {
      tr {
        transition: background 0.3s ease-in-out;
        &:hover {
          background: linear-gradient(
            270deg,
            rgba(118, 197, 255, 0.5) 0%,
            rgba(118, 197, 255, 0.06) 41%,
            rgba(118, 197, 255, 0.06) 60%,
            rgba(118, 197, 255, 0.5) 100%
          );
        }
      }
    }
  }
}
</style>
