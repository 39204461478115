export const PublicSize = {
  /** @name 表单弹窗尺寸 */
  FormDetailSize: '850px',
  FormModalSize: '800px',
  DeleteModalSize: '442px',
  /** @name 大屏更多记录弹窗尺寸 */
  MoreLogModalSize: '1000px',
  LargeLogModalSize: '1400px',
};

/** @name 进出方向值置换 */
export function exchangeDirectionValue(t) {
  switch (t) {
    case 'IN':
      return '进';
    case 'IN_OUT':
      return '进/出';
    case 'OUT':
      return '出';
    default:
      return t;
  }
}
