import dayjs from 'dayjs';
import OssImage from '@/views/screen/components/oss-image.vue';

export const dangerRecordsColumns = [
  {
    title: '隐患单号',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: '上报人员',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: '隐患级别',
    dataIndex: 'dangerLevel',
    key: 'dangerLevel',
    ellipsis: true,
  },
  {
    title: '隐患位置',
    dataIndex: 'dangerLocation',
    key: 'dangerLocation',
    ellipsis: true,
  },
  {
    title: '隐患时间',
    dataIndex: 'dangerTime',
    key: 'dangerTime',
    ellipsis: true,
    format: text => dayjs(text).format('YYYY-MM-DD'),
  },
  {
    title: '隐患照片',
    dataIndex: 'dangerPictures',
    key: 'dangerPictures',
    ellipsis: true,
    render: (h, item) => (
      <div>
        {item.dangerPictures &&
          item.dangerPictures.map(ele => (
            <OssImage
              basePath="/oss/oss"
              ossPath={ele.value}
              class="table-img"
            />
          ))}
      </div>
    ),
  },
];

//巡检记录
export const inspectRecordsColumns = [
  {
    title: '巡检编号',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: '巡检人',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: '巡检位置',
    dataIndex: 'inspectionLocation',
    key: 'inspectionLocation',
    ellipsis: true,
  },
  {
    title: '巡检状态',
    dataIndex: 'inspectionState',
    key: 'inspectionState',
    ellipsis: true,
  },
  {
    title: '巡检时间',
    dataIndex: 'inspectionTime',
    key: 'inspectionTime',
    ellipsis: true,
    format: text => dayjs(text).format('YYYY-MM-DD'),
  },
  {
    title: '巡检照片',
    dataIndex: 'inspectionPictures',
    key: 'inspectionPictures',
    ellipsis: true,
    render: (h, item) => (
      <div>
        {item.inspectionPictures &&
          item.inspectionPictures.map(ele => (
            <OssImage
              basePath="/oss/oss"
              ossPath={ele.value}
              class="table-img"
            />
          ))}
      </div>
    ),
  },
];

//其他检查
export const inspectSafeRecordsColumns = [
  {
    title: '检查编号',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: '检查人',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: '检查部位',
    dataIndex: 'inspectionLocation',
    key: 'inspectionLocation',
    ellipsis: true,
  },
  {
    title: '项目',
    dataIndex: 'projectName',
    key: 'projectName',
    ellipsis: true,
  },
  {
    title: '检查时间',
    dataIndex: 'inspectionTime',
    key: 'inspectionTime',
    ellipsis: true,
    format: text => dayjs(text).format('YYYY-MM-DD'),
  },
  {
    title: '检查照片',
    dataIndex: 'inspectionPictures',
    key: 'inspectionPictures',
    ellipsis: true,
    render: (h, item) => (
      <div>
        {item.inspectionPictures &&
          item.inspectionPictures.map(ele => (
            <OssImage
              basePath="/oss/oss"
              ossPath={ele.value}
              class="table-img"
            />
          ))}
      </div>
    ),
  },
];

//企业检查
export const inspectEnterpriseRecordsData = [
  {
    title: '检查编号',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: '检查人',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: '检查类别',
    dataIndex: 'inspectionType',
    key: 'inspectionType',
    ellipsis: true,
  },
  {
    title: '项目',
    dataIndex: 'projectName',
    key: 'projectName',
    ellipsis: true,
  },
  {
    title: '检查时间',
    dataIndex: 'inspectionTime',
    key: 'inspectionTime',
    ellipsis: true,
    format: text => dayjs(text).format('YYYY-MM-DD'),
  },
  {
    title: '检查照片',
    dataIndex: 'inspectionPictures',
    key: 'inspectionPictures',
    ellipsis: true,
    render: (h, item) => (
      <div>
        {item.inspectionPictures &&
          item.inspectionPictures.map(ele => (
            <OssImage
              basePath="/oss/oss"
              ossPath={ele.value}
              class="table-img"
            />
          ))}
      </div>
    ),
  },
];

//项目检查记录
export const inspectProjectRecordsData = [
  {
    title: '检查编号',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: '检查人',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: '检查部位',
    dataIndex: 'inspectionLocation',
    key: 'inspectionLocation',
    ellipsis: true,
  },
  {
    title: '项目',
    dataIndex: 'projectName',
    key: 'projectName',
    ellipsis: true,
  },
  {
    title: '检查时间',
    dataIndex: 'inspectionTime',
    key: 'inspectionTime',
    ellipsis: true,
    format: text => dayjs(text).format('YYYY-MM-DD'),
  },
  {
    title: '检查照片',
    dataIndex: 'inspectionPictures',
    key: 'inspectionPictures',
    ellipsis: true,
    render: (h, item) => (
      <div>
        {item.inspectionPictures &&
          item.inspectionPictures.map(ele => (
            <OssImage
              basePath="/oss/oss"
              ossPath={ele.value}
              class="table-img"
            />
          ))}
      </div>
    ),
  },
];
//监督检查记录
export const inspectSuperviseRecordsData = [
  {
    title: '检查编号',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: '检查人',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: '单位名称',
    dataIndex: 'companyName',
    key: 'companyName',
    ellipsis: true,
  },
  {
    title: '项目',
    dataIndex: 'projectName',
    key: 'projectName',
    ellipsis: true,
  },
  {
    title: '检查时间',
    dataIndex: 'inspectionTime',
    key: 'inspectionTime',
    ellipsis: true,
    format: text => dayjs(text).format('YYYY-MM-DD'),
  },
  {
    title: '检查照片',
    dataIndex: 'inspectionPictures',
    key: 'inspectionPictures',
    ellipsis: true,
    render: (h, item) => (
      <div>
        {item.inspectionPictures &&
          item.inspectionPictures.map(ele => (
            <OssImage
              basePath="/oss/oss"
              ossPath={ele.value}
              class="table-img"
            />
          ))}
      </div>
    ),
  },
];
