var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-table h-full flex flex-col"},[_c('table',{staticClass:"w-full",style:({ tableLayout: _vm.tableLayout })},[_c('colgroup',_vm._l((_vm.columns),function(item,index){return _c('col',{key:index,style:({ width: item.width })})}),0),_c('thead',[_c('tr',_vm._l((_vm.columns),function(item,index){return _c('th',{key:index,staticClass:"truncate"},[_vm._v(" "+_vm._s(item.title || '-')+" ")])}),0)])]),_c('div',{staticClass:"flex-1 overflow-hidden"},[(_vm.infiniteScroll)?_c('vueSeamlessScroll',{staticStyle:{"cursor":"pointer"},attrs:{"class-option":_vm.classOption,"data":_vm.datasource},nativeOn:{"click":function($event){return _vm.handleBodyClick.apply(null, arguments)}}},[_c('table',{staticClass:"w-full",style:({ tableLayout: _vm.tableLayout })},[_c('colgroup',_vm._l((_vm.columns),function(item,index){return _c('col',{key:index,style:({ width: item.width })})}),0),_c('tbody',_vm._l((_vm.datasource),function(item,index){return _c('tr',{key:index},_vm._l((_vm.columns),function(td,i){return _c('td',{key:i,staticClass:"truncate",attrs:{"data-index":index,"title":td.format
                  ? td.format(item[td.key])
                  : item[td.key] === -1
                  ? '-'
                  : item[td.key] ?? '-'}},[(td.render)?_c('CustomRender',{attrs:{"render":h => td.render(h, item)}}):[_vm._v(" "+_vm._s(td.format ? td.format(item[td.key]) : item[td.key] === -1 ? '-' : item[td.key] ?? '-')+" ")]],2)}),0)}),0)]),(!_vm.finished)?_vm._t("loading-text",function(){return [_c('div',{staticClass:"flex justify-center items-center",staticStyle:{"gap":"8px"}},[_c('a-icon',{attrs:{"type":"sync","spin":""}}),_vm._v(_vm._s(_vm.loadingText)+" ")],1)]}):_vm._e()],2):_c('x-scroll-view',{attrs:{"disabled":false,"immediateCheck":true,"lower-threshold":0},on:{"scrolltolower":_vm.loadMore}},[_c('table',{staticClass:"w-full",style:({ tableLayout: _vm.tableLayout })},[_c('colgroup',_vm._l((_vm.columns),function(item,index){return _c('col',{key:index,style:({ width: item.width })})}),0),_c('tbody',_vm._l((_vm.datasource),function(item,index){return _c('tr',{key:index,on:{"click":function($event){return _vm.handleCellClick(item, index)}}},_vm._l((_vm.columns),function(td,i){return _c('td',{key:i,staticClass:"truncate",attrs:{"title":td.format
                  ? td.format(item[td.key])
                  : item[td.key] === -1
                  ? '-'
                  : item[td.key] ?? '-'}},[(td.render)?_c('CustomRender',{attrs:{"render":h => td.render(h, item)}}):[_vm._v(" "+_vm._s(td.format ? td.format(item[td.key]) : item[td.key] === -1 ? '-' : item[td.key] ?? '-')+" ")]],2)}),0)}),0)]),(!_vm.finished)?_vm._t("loading-text",function(){return [_c('div',{staticClass:"flex justify-center items-center",staticStyle:{"gap":"8px"}},[_c('a-icon',{attrs:{"type":"sync","spin":""}}),_vm._v(_vm._s(_vm.loadingText)+" ")],1)]}):_vm._e()],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }