<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UploadService } from '@triascloud/services';
import { Preview } from '@triascloud/x-components';

@Component()
export default class OssImage extends Vue {
  @Prop({ type: Array | String, required: true }) ossPath;
  @Prop({ type: String, default: '/oss/iot/oss' }) basePath;
  @Prop({ type: Array, default: () => [] }) imgNameList;

  abPath = null;

  @Watch('ossPath', { immediate: true })
  async handleOssPathChange(path) {
    if (!path) {
      this.abPath = null;
    } else {
      await this.loadOssPath();
    }
  }

  openImg(e, index) {
    const path = this.abPath instanceof Array ? this.abPath : [this.abPath];
    const urls = path.map((item, index) => ({
      url: item,
      name: this.imgNameList.length ? this.imgNameList[index] : '预览',
    }));
    Preview.createModal({
      urls,
      index,
    });
    e.stopPropagation();
  }

  loading = false;
  async loadOssPath() {
    const basePath = this.basePath;
    const customService = new UploadService(basePath);
    this.abPath = await customService.getAuth(this.ossPath);
  }

  ossImg() {
    if (this.ossPath instanceof Array && this.ossPath.length > 1) {
      return (
        <div>
          {this.ossPath.map((item, index) => (
            <span onClick={e => this.openImg(e, index)}>
              <XOssImage
                basePath={this.basePath}
                ossPath={item}
                class="table-img"
              />
            </span>
          ))}
        </div>
      );
    } else {
      return (
        <span onClick={e => this.openImg(e)}>
          <XOssImage
            basePath={this.basePath}
            ossPath={
              this.ossPath instanceof Array ? this.ossPath[0] : this.ossPath
            }
            class="table-img"
          />
        </span>
      );
    }
  }

  render() {
    return this.ossImg();
  }
}
</script>
<style lang="less"></style>
