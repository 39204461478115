import dayjs from 'dayjs';
import { formatSeconds } from '../../utils';

// 获取工人基本信息
export const baseLaborData = {
  adminAttendanceNum: 0,
  adminAttendanceRate: 0,
  adminNum: 0,
  adminPresenceNum: 0,
  builderAttendanceNum: 0,
  builderAttendanceRate: 0,
  builderNum: 0,
  builderPresenceNum: 0,
  safeAttendanceNum: 0,
  safeAttendanceRate: 0,
  safeNum: 0,
  safePresenceNum: 0,
  todayAttendanceNum: 0,
  todayEnterNum: 0,
  todayExitNum: 0,
  todayPresenceNum: 0,
  totalNumPeople: 0,
  yesterdayPresenceNum: 0,
};

// 工种在场状态
export const workerPresentStatusColumns = [
  {
    title: '工种类型',
    key: 'jobType',
    width: '20%',
    format: text => text || '-',
  },
  {
    title: '总人数',
    key: 'totalNumPeople',
    width: '20%',
  },
  {
    title: '今日出勤',
    key: 'attendanceNum',
  },
  {
    title: '在场人数',
    key: 'presenceNum',
  },
  {
    title: '出勤率',
    key: 'attendanceRate',
    format: text => (text >= 0 ? text + '%' : '-'),
  },
];

// 单位考勤状态
export const unitAttendanceScrollTbaleColumns = [
  {
    title: '参建单位',
    key: 'companyName',
  },
  {
    title: '总人数',
    key: 'totalNumPeople',
  },
  {
    title: '今日出勤',
    key: 'attendanceNum',
    format: text => (text >= 0 ? text : '-'),
  },
  {
    title: '今日在场',
    key: 'presenceNum',
    format: text => (text >= 0 ? text : '-'),
  },
  {
    title: '出勤率',
    key: 'attendanceRate',
    format: text => (text >= 0 ? text + '%' : '-'),
  },
];

// 安管在岗时间
export const safeScrollTbaleColumns = [
  {
    title: '姓名',
    key: 'fullName',
  },
  {
    title: '班组',
    key: 'teamName',
  },
  {
    title: '在岗时间',
    key: 'workingTime',
    format: text => (text ? formatSeconds(text, true) : 0),
  },
];

// 班组信息
export const classScrollTbaleColumns = [
  {
    title: '班组名称',
    key: 'teamName',
  },
  {
    title: '班组人数',
    key: 'teamNum',
  },
  {
    title: '参建单位',
    key: 'companyName',
  },
];

// 单位信息
export const unitScrollTbaleColumns = [
  {
    title: '单位名称',
    key: 'companyName',
  },
  {
    title: '单位人数',
    key: 'companyNum',
  },
  {
    title: '统一社会信用代码',
    key: 'companyCode',
  },
];

// 良好行为
export const goodBehScrollTbaleColumns = [
  {
    title: '姓名',
    key: 'fullName',
  },
  {
    title: '班组',
    key: 'teamName',
  },
  {
    title: '良好行为说明',
    key: 'behaviorDesc',
  },
];

// 出勤记录
export const laborAccessRecordScrollTbaleColumns = [
  {
    title: '姓名',
    key: 'fullName',
  },
  {
    title: '设备',
    key: 'deviceName',
  },
  {
    title: '进出场',
    key: 'accessState',
  },
  {
    title: '出入时间',
    key: 'accessTime',
    format: text => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
  },
];

// 工人良好行为
export const workerGoodsBehavColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    width: 60,
    key: 'index',
    customRender(text, record, index) {
      return index + 1;
    },
  },
  {
    title: '姓名',
    key: 'fullName',
    dataIndex: 'fullName',
    ellipsis: true,
  },
  {
    title: '身份证号',
    key: 'idCard',
    dataIndex: 'idCard',
    ellipsis: true,
  },
  {
    title: '班组',
    key: 'teamName',
    dataIndex: 'teamName',
    ellipsis: true,
  },
  {
    title: '良好行为说明',
    key: 'behaviorDesc',
    dataIndex: 'behaviorDesc',
    ellipsis: true,
  },
  {
    title: '良好行为时间',
    key: 'behaviorTime',
    ellipsis: true,
    dataIndex: 'behaviorTime',
    customRender: text => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
  },
];

// 奖励记录
export const workerRewardAndPunishColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    width: 60,
    key: 'index',
    customRender(text, record, index) {
      return index + 1;
    },
  },
  {
    title: '姓名',
    key: 'fullName',
    dataIndex: 'fullName',
    width: 100,
  },
  {
    title: '身份证号',
    key: 'idCard',
    dataIndex: 'idCard',
    ellipsis: true,
    width: 200,
  },
  {
    title: '奖惩类型',
    key: 'rpType',
    dataIndex: 'rpType',
    ellipsis: true,
  },
  {
    title: '奖惩事由',
    key: 'rpDesc',
    dataIndex: 'rpDesc',
    ellipsis: true,
    width: 300,
  },
  {
    title: '参建单位',
    key: 'companyName',
    dataIndex: 'companyName',
    ellipsis: true,
    width: 200,
  },
  {
    title: '项目',
    key: 'projectName',
    dataIndex: 'projectName',
    ellipsis: true,
    width: 300,
  },
];

// 安全教育记录
export const safeEducationDialogColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    width: 60,
    key: 'index',
    customRender(text, record, index) {
      return index + 1;
    },
  },
  {
    title: '培训主题',
    key: 'educationTopic',
    dataIndex: 'educationTopic',
    ellipsis: true,
    width: 260,
  },
  {
    title: '培训类型',
    key: 'educationType',
    dataIndex: 'educationType',
    ellipsis: true,
    width: 100,
  },
  {
    title: '培训时间',
    key: 'educationTime',
    dataIndex: 'educationTime',
    ellipsis: true,
    width: 200,
    customRender: text => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '培训时长',
    key: 'educationDuration',
    dataIndex: 'educationDuration',
    ellipsis: true,
    width: 100,
    // customRender: text => `${text}小时`,
  },
  {
    title: '培训人',
    key: 'speaker',
    dataIndex: 'speaker',
    ellipsis: true,
    width: 100,
  },
  {
    title: '培训人数',
    key: 'educationNum',
    dataIndex: 'educationNum',
    ellipsis: true,
    width: 100,
  },
  {
    title: '培训地点',
    key: 'educationLocation',
    dataIndex: 'educationLocation',
    ellipsis: true,
    width: 200,
  },
  {
    title: '培训照片',
    key: 'educationPictures',
    dataIndex: 'educationPictures',
    ellipsis: true,
    width: 200,
    scopedSlots: { customRender: 'dImages' },
  },
];

// 出入记录弹窗
export const workerInAndOutDialogColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    width: 60,
    key: 'index',
    customRender(text, record, index) {
      return index + 1;
    },
  },
  {
    title: '姓名',
    key: 'fullName',
    dataIndex: 'fullName',
    width: 100,
  },
  {
    title: '身份证号',
    key: 'idCard',
    dataIndex: 'idCard',
    ellipsis: true,
    width: 200,
  },
  {
    title: '班组',
    key: 'teamName',
    dataIndex: 'teamName',
    ellipsis: true,
    width: 100,
  },
  {
    title: '设备名称',
    key: 'deviceName',
    dataIndex: 'deviceName',
    ellipsis: true,
    width: 140,
  },
  {
    title: '设备码',
    key: 'deviceCode',
    width: 200,
    ellipsis: true,
    dataIndex: 'deviceCode',
  },
  {
    title: '进出场',
    key: 'accessState',
    width: 80,
    ellipsis: true,
    dataIndex: 'accessState',
  },
  {
    title: '进出场时间',
    key: 'accessTime',
    width: 160,
    ellipsis: true,
    dataIndex: 'accessTime',
    customRender: text => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '进出图片',
    key: 'accessPicture',
    dataIndex: 'accessPicture',
    ellipsis: true,
    width: 200,
    scopedSlots: { customRender: 'inImg' },
    // render: (h, item) => {
    //   const imgLists =
    //     item.accessPicture &&
    //     item.accessPicture.split(',').map(item => item.value);
    //   return (
    //     <div>
    //       {imgLists.map((ele, index) => (
    //         <x-oss-image
    //           key={index}
    //           basePath="/oss/oss"
    //           ossPath={ele.value}
    //           class="table-img"
    //           onClick={() => openImg(imgLists, index)}
    //         />
    //       ))}
    //     </div>
    //   );
    // },
  },
  {
    title: '操作',
    dataIndex: '',
    key: 'action',
    width: 100,
    scopedSlots: { customRender: 'action' },
  },
];

// 良好行为(详情)
export const detailGoodBehaveColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    width: 60,
    key: 'index',
    customRender(text, record, index) {
      return index + 1;
    },
  },
  {
    title: '姓名',
    key: 'fullName',
    dataIndex: 'fullName',
  },
  {
    title: '身份证号',
    key: 'idCard',
    dataIndex: 'idCard',
    ellipsis: true,
  },
  {
    title: '良好行为说明',
    key: 'behaviorDesc',
    dataIndex: 'behaviorDesc',
    ellipsis: true,
  },
  {
    title: '良好行为时间',
    key: 'behaviorTime',
    ellipsis: true,
    dataIndex: 'behaviorTime',
    customRender: text => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '参建单位',
    key: 'companyName',
    dataIndex: 'companyName',
    ellipsis: true,
  },
  {
    title: '项目',
    key: 'projectName',
    dataIndex: 'projectName',
    ellipsis: true,
  },
];

// 不良行为(详情)
export const detailBadBehaveColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    width: 60,
    key: 'index',
    customRender(text, record, index) {
      return index + 1;
    },
  },
  {
    title: '姓名',
    key: 'fullName',
    dataIndex: 'fullName',
    ellipsis: true,
  },
  {
    title: '不良行为类别',
    key: 'type',
    dataIndex: 'type',
    ellipsis: true,
  },
  {
    title: '事件级别',
    key: 'eventLevel',
    dataIndex: 'eventLevel',
    ellipsis: true,
  },
  {
    title: '发生时间',
    key: 'behaviorTime',
    ellipsis: true,
    dataIndex: 'behaviorTime',
    customRender: text => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '事件描述',
    key: 'behaviorDesc',
    dataIndex: 'behaviorDesc',
    ellipsis: true,
  },
  // {
  //   title: '处理时间',
  //   key: 'behaviorTime',
  //   ellipsis: true,
  //   dataIndex: 'behaviorTime',
  //   customRender: text => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
  // },
  {
    title: '处理结果',
    key: 'processingResult',
    dataIndex: 'processingResult',
    ellipsis: true,
  },
];
