import dayjs from 'dayjs';

export const scrollTbaleColumns = [
  {
    title: '设备名称',
    key: 'deviceName',
  },
  {
    title: '类型',
    key: 'deviceGroup',
    width: 100,
  },
  {
    title: '预警时间',
    key: 'alarmTime',
    format: text => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
  },
];
